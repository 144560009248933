<template>
  <v-layout column>
    <Autocomplete
      :value.sync="internalValue"
      label="Źródło danych"
      :data="items"
      :clearAfterSelection="false"
      :returnObject="false"
      :disabled="disabled"
      :required="required"
      placeholder="Wybierz z jakiego systemu są dane..."
    >
    </Autocomplete>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        { value: 1, text: "Armedis" },
        { value: 2, text: "Finezjo" },
        { value: 3, text: "Bookly" },
        { value: 4, text: "Dobry Gabinet" },
        { value: 5, text: "Frida Medical" },
        { value: 6, text: "Halo24" },
        { value: 7, text: "iGabinet" },
        { value: 8, text: "MedFile" },
        { value: 9, text: "Mediporta" },
        { value: 10, text: "Medispa" },
        { value: 11, text: "MyDr" },
        { value: 12, text: "Proassist" },
        { value: 13, text: "Plandok" },
        { value: 14, text: "RSQ" },
        { value: 15, text: "Timebox" },
        { value: 16, text: "TimeHub" },
        { value: 17, text: "Znany Lekarz" },
        { value: 18, text: "Kuracjusz" },
        { value: 19, text: "Nakiedy" },
        { value: 20, text: "Znajdź Gabinet" },
        { value: 21, text: "Booksy" },
        { value: 22, text: "Versum" },
        { value: 24, text: "Asphys"},
        { value: 23, text: "Inne" },
      ],
    };
  },
  components: {
    Autocomplete: () => import("@/components/Autocomplete"),
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
};
</script>